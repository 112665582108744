import React from "react";
import { useNavigate } from "react-router-dom";
import EmailTextInput from "../components/text_inputs/text_cpf_input";
import PasswordTextInput from "../components/text_inputs/text_password_input";
import PageBase from "../components/grids/page_login_base";
import Divider from "../components/grids/divider";
import TitleH2Text from "../components/texts/title_h2_text";
import TitleH1Text from "../components/texts/title_h1_text";
import { Button } from "../components/buttons";
import api from "../services";

import imageLogin from "../assets/img-login.png";
import IconGoogle from "../assets/icons/google_icon";

interface LoginProps {
  onLogin?: () => void;
}
const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const navigate = useNavigate();
  const [cpf, setCpf] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleLogin = async () => {
    try {
      const response = await api.post("/auth/auth/local", { cpf: cpf, password });
      if (response.status === 200) {
        const { token, user } = response.data;
        localStorage.setItem("token", token);
        // Optionally, you can call the onLogin callback
        if (onLogin) onLogin();
        // Navigate to the dashboard
        navigate("/dashboard");
      } else {
        // Handle login failure
        console.error("Login failed:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };
  

  const siginWithGoogle = () => {
    navigate("/");
  };

  return (
    <PageBase>
      <div className="flex flex-col md:flex-row min-720:justify-center h-screen">
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-10 px-10 md:gap-40 md:px-40">
          <div className="flex flex-col">
            <TitleH1Text text="Acesse sua conta" />
            <Divider height="40px" />
            <TitleH2Text
              text="Seja bem-vindo(a)! Por favor, insira suas credenciais para a nossa plataforma ou Crie uma conta"
              textColor="DEFAULT"
            />
            <form onSubmit={(event) => event.preventDefault()}>
              <EmailTextInput
                textLabel="CPF: "
                placeholder="000.000.000-00"
                onChange={setCpf}
              />
              <PasswordTextInput
                textLabel="senha:  "
                placeholder="****"
                value={password}
                onChange={setPassword}
              />
              <Divider height="20px" />
              <span
                className="w-96 text-brand-black text-sm font-medium font-['General Sans Variable'] leading-tight underline"
                onClick={forgotPassword}
              >
                Esqueci minha senha?
              </span>
              <Button
                label="Entrar"
                color="bg-brand-black"
                textColor="text-text-light"
                action={handleLogin}
              />
            </form>
            <div className="w-full h-full flex items-center gap-8">
              <div className="flex-1 border border-text"></div>
              <div className=" flex flex-col justify-center items-center gap-4">
                <div className="text-text text-sm font-medium dark:text-text">
                  ou
                </div>
              </div>
              <div className="flex-1 border border-text"></div>
            </div>
            {/* <Button
              startIcon={<IconGoogle />}
              label="Continuar com o Google"
              color="bg-neutral-neutral10"
              textColor="text-text-dark"
              action={siginWithGoogle}
            /> */}
          </div>
        </div>
        <div className="hidden md:block md:w-1/2">
          <img
            src={imageLogin}
            alt="Imagem aleatória"
            className="object-cover w-full h-full object-left"
          />
        </div>
      </div>
    </PageBase>
  );
};

export default Login;
