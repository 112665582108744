import React from 'react';
import LogoOrdaIcon from '../../assets/icons/logo_orda_w_icon';

interface HeaderDrawerProps {
    action: () => void;
}

const HeaderDrawer: React.FC<HeaderDrawerProps> = ({action}) => {
    return (
        <div className="w-56 h-14 px-3 py-2 justify-start items-center gap-2 inline-flex">
            <div className="w-8 h-8 relative">
                <div className="w-8 h-8 left-0 top-0 absolute bg-brand-cosmic rounded-lg flex justify-center items-center">
                    <LogoOrdaIcon />
                </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                <div className="self-stretch justify-between items-center inline-flex">
                    <div className="justify-start items-center gap-2 flex">
                        <div className="text-black text-base font-medium font-['General Sans Variable'] leading-normal">
                            Orda
                        </div>
                        <div className="px-1.5 py-1 bg-purple-100 rounded-2xl border border-purple-400 justify-center items-center gap-2 flex">
                            <div className="text-purple-600 text-xs font-medium font-['General Sans Variable']">
                                Beta
                            </div>
                        </div>
                    </div>
                    <button className="w-5 h-5 relative" onClick={action} >
                        <span className="material-symbols-rounded text-text text-lg font-medium font-['Material Icons']">close_fullscreen</span>
                    </button>
                </div>
                <div className="text-neutral-500 text-xs font-medium font-['General Sans Variable']">
                    Gerenciador Basic
                </div>
            </div>
        </div>
    );
};

export default HeaderDrawer;