import React, { useState } from "react";
import { List, ListItem } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";

import HeaderDrawer from "../headers/header_drawer";
import FooterDrawer from "../footers/footer_drawer";
import MenuDrawerItem from "../buttons/menu_drawer_itens";

interface MenuDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({ isOpen, onClose }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
    };

    const handleDrawerClose = () => {
        isOpen = true;
    };

    return (
        <div className={`left-0 top-0 h-full bg-neutral-50 ${isOpen ? 'block' : 'hidden'}`}>
            <List>
                <ListItem>
                    <HeaderDrawer action={handleDrawerClose} />
                </ListItem>
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames="menu-item"
                    unmountOnExit
                >
                    <MenuDrawerItem
                        icon="home"
                        text="Página Inicial"
                        selectedIndex={selectedIndex}
                        handleListItemClick={() => handleListItemClick(0)}
                    />
                </CSSTransition>
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames="menu-item"
                    unmountOnExit
                >
                    <MenuDrawerItem
                        icon="search"
                        text="Buscar"
                        selectedIndex={selectedIndex}
                        handleListItemClick={() => handleListItemClick(1)}
                    />
                </CSSTransition>
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames="menu-item"
                    unmountOnExit
                >
                    <MenuDrawerItem
                        icon="bar_chart_4_bars"
                        text="Relatórios"
                        selectedIndex={selectedIndex}
                        handleListItemClick={() => handleListItemClick(2)}
                    />
                </CSSTransition>
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames="menu-item"
                    unmountOnExit
                >
                    <MenuDrawerItem
                        icon="account_tree"
                        text="Automação"
                        selectedIndex={selectedIndex}
                        handleListItemClick={() => handleListItemClick(3)}
                    />
                </CSSTransition>
                <div className="flex-grow">
                    <FooterDrawer name="Felipe Consalter" cargo="Develop" />
                </div>
            </List>
        </div>
    );
};

export default MenuDrawer;
