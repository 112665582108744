import React from "react";
import ListItem from "@material-ui/core/ListItem";

interface MenuDrawerItemProps {
  selectedIndex: number;
  handleListItemClick: (index: number) => void;
  icon: string;
  text: string;
}

const MenuDrawerItem: React.FC<MenuDrawerItemProps> = ({
  selectedIndex,
  handleListItemClick,
  icon,
  text,
}) => {
  return (
    <ListItem
      button
      selected={selectedIndex === 1}
      onClick={() => handleListItemClick(1)}
      className="h-9 rounded-md justify-start items-center gap-2 inline-flex"
    >
      <div className=" px-1 pt-1 pb-0.5 justify-center items-center flex" />
      <span className="material-symbols-rounded text-text">{icon}</span>
      <div className="flex-grow flex-shrink flex-basis-0 flex-col justify-start items-start">
        <div className="self-stretch text-text text-sm font-medium font-['General Sans Variable'] leading-tight">
          {text}
        </div>
      </div>
    </ListItem>
  );
};

export default MenuDrawerItem;
