// src/Home.tsx
import React from 'react';
import BasePage from '../components/grids/page_login_base';
import MenuDrawer from '../components/grids/menu_drawer';
import FlowFeature from '../components/flows/exemple';

const Home = () => {
  console.log('Home component rendered');
  return (
    <div className="h-screen w-screen bg-neutral-100">
      <div className="flex h-full">
        <MenuDrawer isOpen={true} onClose={() => {}} />
        <div className="flex-1">
          <FlowFeature />
        </div>
      </div>
    </div>
  );
};

export default Home;
