import React from 'react';

type ButtonProps = {
    startIcon?: React.ReactNode;
    label: string;
    endIcon?: React.ReactNode;
    color?: string;
    textColor?: string;
    action?: (() => void) | undefined
};

const Button: React.FC<ButtonProps> = ({ startIcon, label, endIcon, color , textColor, action}) => {
    return (
        <button onClick={action} className={`mt-4 w-full h-11 px-2 py-3 ${color ? color : 'bg-brand-cosmic'} rounded-xl shadow border border-gray-200 justify-center items-center gap-2 inline-flex`}>
            {startIcon && <span>{startIcon}</span>}
            <div className="flex-col justify-start items-start inline-flex">
            <span className={`self-stretch text-sm font-medium font-['General Sans Variable'] leading-tight ${textColor}`}>{label}</span>
            </div>
            {endIcon && <span>{endIcon}</span>}
        </button>
    );
};

export default Button;
