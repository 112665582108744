import React from 'react';

interface FooterDrawerProps {
    name: string;
    cargo: string;
}

const FooterDrawer: React.FC<FooterDrawerProps> = ({ name, cargo }) => {
    return (
        <div className="h-16 p-4 bg-neutral-100 rounded-tl-lg rounded-tr-lg border border-zinc-300 justify-start items-center gap-1 inline-flex">
            <div className="grow shrink basis-0 h-9 justify-start items-center gap-2 flex">
                <div className="w-8 h-8 relative">
                    <div className="w-8 h-8 left-0 top-0 absolute bg-lime-100 rounded-md"></div>
                    <div className="left-[10.67px] top-[2.67px] absolute text-right text-black text-lg font-medium font-['General Sans Variable'] leading-relaxed">
                        F
                    </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-stone-950 text-sm font-medium font-['General Sans Variable'] leading-tight">
                        {name}
                    </div>
                    <div className="self-stretch text-stone-950 text-xs font-normal font-['General Sans Variable']">
                        {cargo}
                    </div>
                </div>
                <div className="p-1 bg-white rounded justify-start items-center gap-1 flex">
                    <div className="w-5 h-5 px-0.5 py-0.5 justify-center items-center flex"></div>
                </div>
            </div>
        </div>
    );
};

export default FooterDrawer;